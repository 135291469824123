<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">增加/修改 档案</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>排序</b></label>
            <el-input type="number" v-model="model.SORT" />
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        sta: {
          show: false,
          loading: false
        },
        model: {},
      }
    },
    methods: {
      init(model) {
        this.sta = { show: true, loading: false }
        if (model == undefined || model == null) {
          this.model = {};
        } else {
          this.model = JSON.parse((JSON.stringify(model)));
        }
      },
      submit() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/DOC/ArchiveApi/Save",
          data: self.model,
          finally() { self.sta.loading = false; },
          completed: function () {
            self.sta.show = false;
            self.$emit("on-saved");
          }
        })
      }
    }
  }
</script>